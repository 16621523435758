import React from "react"
import { LayoutWhite } from "@layout"
import SEO from "@components/seo"
import { Hero4042022Q3 } from "@components/hero"

const NotFoundPage = () => (
  <LayoutWhite backgroundImage="animated" hero={Hero4042022Q3}>
    <SEO title="Page Not Found" />
  </LayoutWhite>
)

export default NotFoundPage
